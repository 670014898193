import React, { useCallback, useEffect, useState } from 'react';
import Sidebar from './sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-regular-svg-icons';
import { faArrowRight, faUser, faVideo } from '@fortawesome/free-solid-svg-icons';
import SimpleMDE, { SimpleMdeReact } from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import MarkdownIt from 'markdown-it';
import { apiURL } from '../../app/lib';
import { useSelector } from 'react-redux';
import { isjwtExpire } from '../../app/jwtcheck';
import { ToastContainer, toast } from 'react-toastify';
import Message from '../headers/Message';
import SubscriptionsTable from './subscriptions/Subscriptiontable';
import htmlToMarkdown from '@wcj/html-to-markdown';
import TasksTable from './tasks/tasksTable';
import CategoryTable from './categories/table';
import VideoTable from './videos/table';
import QuizTable from './quiz/table';

const Quizs = () => {
    const [value, setValue] = useState("");
    const [packages, setpackages] = useState([]);
    const [isLoadingSubmit, setisLoadingSubmit] = useState(false);
    const [isContentLoading, setisContentLoading] = useState(false);
    const [subscription_group, setsubscription_group] = useState('');
    const [isEditModon, setisEditModon] = useState(false);
    const {user} = useSelector(state=>state.login)
    const [categoryName, setcategoryName] = useState('');
    const [categoryID, setcategoryID] = useState(0);
    const [allCategories, setallCategories] = useState(null);
    const [corespondingTask, setcorespondingTask] = useState(null);
    const [question, setquestion] = useState('');
    const [taskid, settaskid] = useState('');
    const [answers, setanswers] = useState([]);
    const [correctAnswer, setcorrectAnswer] = useState('');

    const fetchAllCat = ()=>{
        fetch(apiURL+"/categories",{
            method:"POST",
            headers:{
                "Content-Type":"application/json",
                "Authorization":user?.token
            },
        })
        .then((res)=>res.json())
        .then((result)=>{
            setallCategories(result?.result)
        })
    }

    
    const fetchCatsTask = (cats)=>{
        setisLoadingSubmit(true)
        setcorespondingTask(null)
        fetch(apiURL+"/tasks-cat/"+cats,{
            method:"POST",
            headers:{
                "Content-Type":"application/json",
                "Authorization":user?.token
            },
        })
        .then((res)=>res.json())
        .then((result)=>{
            setcorespondingTask(result?.result)
            setisLoadingSubmit(false)
        })
    }
    
    
    const handleHubmit=()=>{
        
        if(categoryName==='' || question==='' || taskid==='' || question==='' || answers==='' || correctAnswer===''){
            toast("Empty Input Not Accepted!")
            return 0;
        }

        
        const AnswerToString = JSON.stringify(answers)
        
        setisLoadingSubmit(true)
        fetch(apiURL+"/common",{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
                "Authorization":user?.token
            },
            body:JSON.stringify({
                data:"",
                sql:`INSERT INTO quizs (task_id,question,answers,correct) VALUES ('${taskid}','${question}','${AnswerToString}','${correctAnswer}')`
            })
        })
        .then((res)=>res.json())
        .then((result)=>{
            if(result.status===200){
                toast(result.message)
               
                setquestion('')
               
                setanswers([])
                setcorrectAnswer('')
                fetchAllPackage()
            }else{
                console.log(result);
                toast(result?.message)
            }
            setisLoadingSubmit(false)
            
        })
        .catch(()=>{
            toast("Package Not Saved!")
            setisLoadingSubmit(false)
        })
      

    }
    
    const fetchAllPackage = ()=>{
        setisContentLoading(true)
        fetch(apiURL+"/common",{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
                "Authorization":user?.token
            },
            body:JSON.stringify({
                data:"",
                sql:`SELECT * FROM quizs`
            })
        })
        .then((res)=>res.json())
        .then((result)=>{
            if(result.status===200){
                console.log(result.result);
                setpackages(result?.result)
            }else{
                toast(result?.message)
            }
            setisContentLoading(false)
            
        })
        .catch(()=>{
            toast("Package Not Fetch!")
            setisContentLoading(false)
        })
    }

    const DeletePackage = (id)=>{
        const confirmDelete = window.confirm("Are you sure you want to delete?");
        if(!confirmDelete){
            return 0;
        }
        fetch(apiURL+"/common",{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
                "Authorization":user?.token
            },
            body:JSON.stringify({
                data:"",
                sql:`DELETE FROM quizs WHERE id=${id}`
            })
        })
        .then((res)=>res.json())
        .then((result)=>{
           
            if(result.status===200){
                toast(result?.message)
                fetchAllPackage()
            }else{
                toast(result?.message)
            }
           
            
        })
        .catch(()=>{
            toast("Task Not Deleted!")
            
        })
    }

    const HandleEdit = ()=>{
        if(categoryName===''){
            toast("Empty Input Not Accepted!")
            return 0;
        }
        setisLoadingSubmit(true)
        fetch(apiURL+"/common",{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
                "Authorization":user?.token
            },
            body:JSON.stringify({
                data:"",
                sql:`UPDATE quizs SET task_id='${taskid}',question='${question},answers='${answers},correct='${correctAnswer}' WHERE id=${categoryID}`
            })
        })
        .then((res)=>res.json())
        .then((result)=>{
            if(result.status===200){
                toast(result?.message)
                setcategoryID(0)
                setcategoryName('')
                setquestion('')
                settaskid('')
                setanswers([])
                setcorrectAnswer('')
                setisEditModon(false)
                fetchAllPackage()
            }else{
                toast(result?.message)
            }
            setisLoadingSubmit(false)
            
        })
        .catch(()=>{
            toast("Package Not Saved!")
            setisLoadingSubmit(false)
        })
    }

    const EditPackage = (info)=>{
        console.log(info,'info');
        setisEditModon(true)
        setcategoryID(info?.id)
        setcategoryName(info?.category)
        setquestion(info?.link)
        settaskid(info?.task_id)
    }
    const cancelEdit = ()=>{
        setcategoryID(0)
        setisEditModon(false)
        setcategoryName('')
        setquestion('')
        settaskid('')
        setanswers([])
        setcorrectAnswer('')
    }

    const answerSpliter = (answers)=>{
        const splitedArr = answers?.split(',');
        setanswers(splitedArr)
        console.log(splitedArr);
    }
    
  

    

      useEffect(() => {
        fetchAllPackage()
        fetchAllCat()
      }, []);
    return (
        <div className='flex bg-[#f7f9fc]'>
            
            <Sidebar/>
            
            <div className='w-full lg:w-4/5 p-5 mt-16 lg:p-10 text-left'>
                <div className='border-b pb-2 flex justify-between'>
                    <p className='text-xl font-bold'>Add Questions </p>
                    <FontAwesomeIcon size='xl' icon={faVideo} />
                </div>
                <br></br>
               


                
                <div className='flex justify-between flex-wrap'>
                    <div className='w-full lg:w-7/12'>
                        <div className='space-y-4'>
                            <div className='flex justify-between flex-wrap'>
                                <div className='w-full'>
                                    <p>Question:</p>
                                    <input value={question} onChange={(e)=>setquestion(e.target.value)} className='p-3 w-full border-2 rounded-md border-gray-200' type='text' placeholder='Facebook Task' />
                                </div>
                                
                            </div>

                            <div className='flex justify-between flex-wrap'>
                                <div className='w-full'>
                                    <p>Answers:</p>
                                    <input value={answers} onChange={(e)=>answerSpliter(e.target.value)} className='p-3 w-full border-2 rounded-md border-gray-200' type='text' placeholder='Paris,Dhaka, London, New York' />
                                    
                                </div>
                                
                                
                            </div>

                            <div className='flex justify-between flex-wrap'>
                                <div className='w-full'>
                                    <p>Correct Option:</p>
                                    <input value={correctAnswer} onChange={(e)=>setcorrectAnswer(e.target.value)} className='p-3 w-full border-2 rounded-md border-gray-200' type='text' placeholder='Dhaka' />
                                </div>
                                
                                
                            </div>


                            <div className='flex justify-between flex-wrap'>
                                <div className='w-full'>
                                    <p>Category:</p>
                                    <select className='p-3 w-full border-2 rounded-md border-gray-200' type='text' placeholder='Facebook Task' onChange={(e)=>{setcategoryName(e.target.value); fetchCatsTask(e.target.value)}}>
                                        <option selected >Select Category</option>
                                        {
                                            allCategories&&Array.isArray(allCategories)&&allCategories.map((category)=>{
                                                return <option value={category?.name}>{category?.name}</option>
                                            })
                                        }
                                        
                                    </select>
                                    
                                </div>
                                
                            </div>


                            
                            <div className='flex justify-between flex-wrap'>
                                <div className='w-full'>
                                    <p>Task:</p>
                                    <select className='p-3 w-full border-2 rounded-md border-gray-200' type='text' placeholder='Facebook Task' onChange={(e)=>settaskid(e.target.value)}>
                                        <option selected >{isLoadingSubmit?"Loading...":"Select Task"}</option>
                                        {
                                            corespondingTask&&Array.isArray(corespondingTask)&&corespondingTask.map((task)=>{
                                                return <option value={task?.id}>{task?.task_title}</option>
                                            })
                                        }
                                    </select>
                                    
                                </div>
                                
                            </div>
                            

                            
                            
                        </div>

                        <br></br>
                        <div className='flex justify-start'>
                            {!isEditModon?<div  disabled={isLoadingSubmit} onClick={handleHubmit} className='p-3 cursor-pointer bg-blue-700 text-white w-full lg:w-6/12 border-2 rounded-md flex justify-center items-center'>
                                <p className='mr-2 uppercase'>{isLoadingSubmit?'Loading...':'Save Changes'}</p>
                                
                            </div>:
                            <div className='w-full flex space-x-5'>
                                <div  disabled={isLoadingSubmit} onClick={HandleEdit} className='p-3 cursor-pointer bg-green-700 text-white w-full lg:w-3/12 border-2 rounded-md flex justify-center items-center'>
                                    <p className='mr-2 uppercase'>{isLoadingSubmit?'Loading...':'Save Edits'}</p>
                                    
                                </div>
                                <button onClick={cancelEdit}>Cancel Edit</button>
                            </div>
                            }
                        </div>
                    </div>
                    <div className='w-full lg:w-5/12 p-5'>
                        <p className='text-2xl'>Q. {question}</p>
                        <div>
                            {
                                answers&&Array.isArray(answers)
                                &&answers
                                .map((answer, index)=>{
                                    return <p key={index} className={`p-2 border rounded-md m-3 ${answer===correctAnswer&&"bg-green-200"}`}>{answer}</p>
                                })
                            }
                            
                        </div>
                    </div>
                </div>
                <br></br>
                <hr></hr>
                <br></br>
                <div className='border-b pb-2 flex justify-between'>
                    <p className='text-xl font-bold'> All Tasks ({packages?.length})</p>
                    
                    <FontAwesomeIcon size='xl' icon={faUser} />
                </div>
                <div className='relative'>
                    {
                        isContentLoading&&
                        <div className='absolute bg-[rgba(0,0,0,0.2)] w-full left-0 top-0 h-full z-50 p-10'><Message message={"Loading..."} /></div>
                    }
                    {
                        !isContentLoading&&
                        <QuizTable EditPackage={EditPackage} DeletePackage={DeletePackage} elements={packages} task={taskid}/>
                    }
                </div>

            </div>
            


        </div>
    );
}

export default Quizs;
