import { faCheckSquare, faEdit, faEye } from '@fortawesome/free-regular-svg-icons';
import { faCancel, faRetweet, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useState } from 'react';


const SubmittedPaymentTable = ({ EditPackage, DeletePackage, subscriptions,updateTaskStatus }) => {
  const [selectedTask, setselectedTask] = useState(null);
  const [showModal, setshowModal] = useState(false);
 

  const sanitizeHtml = (html) => {
    
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.innerHTML;
  };

  const onOffModal = (task=null)=>{
    setshowModal(!showModal)
    if(!task){
      setselectedTask(null)
    }else{
      setselectedTask(task)
      
    }
    
  }
  useEffect(() => {
    
  }, []);
  return (
    <div className='relative'>


      {/* Modal */}
      {showModal&&selectedTask?.id&&<div className='fixed top-0 left-0 w-full h-screen z-50 p-2 lg:p-5 bg-[rgba(0,0,0,0.5)] flex justify-center'>
        <div className='bg-white transition-all w-11/12 p-3 lg:p-5 rounded-md h-fit max-h-[90vh] overflow-y-auto'>
          <div className='flex justify-between items-center p-2 pb-3 border-b'>
            <div>
              <h1 className='text-xl'>Paid for {selectedTask?.name} Package</h1>
              <p className='text-gray-500 text-xs'>Purchased by {selectedTask?.full_name} | {selectedTask?.subscription_start}</p>
            </div>
            <p className='cursor-pointer text-xl' onClick={onOffModal}>&times;</p>
          </div>

          <div className='my-5'>
            <h1 className='text-xl text-red-700 mb-2'>Package Description</h1>
            <hr></hr>
            <br></br>
            <div className='text-gray-700' dangerouslySetInnerHTML={{ __html: sanitizeHtml(selectedTask?.description) }} />
          </div>

          <div className='my-5'>
            <h1 className='text-xl text-red-700 mb-2'>About User</h1>
            <hr></hr>
            <br></br>
            <div>
              <p>Name: {selectedTask?.full_name}</p>
              <p>Email: {selectedTask?.email}</p>
              <p>Phone: {selectedTask?.phone}</p>
              <p>Subscribed In: {selectedTask?.subscription}</p>
              <p>Points: {selectedTask?.points}</p>
              
            </div>
            
            <br></br>
            <h1 className='text-xl text-red-700 mb-2'>Payment Info</h1>
            <hr></hr>
            <br></br>
            <div className=''>
              <p>Method: {selectedTask?.payment_method}</p>
              <p>Trx id:: {selectedTask?.transaction_id}</p>
              <p>Payment Id: {selectedTask?.payment_id}</p>
              <p>Account Number: {selectedTask?.account_number}</p>
              <p>Purchased Package: {selectedTask?.name}</p>
              <p>Pay at: {selectedTask?.pay_at}</p>
            </div><br></br>
            <div>
              <div className='flex space-x-2 bg-white shadow-md m-2 p-5 border'>
                <p onClick={()=>updateTaskStatus(selectedTask?.user_id,"completed",selectedTask?.subs_id, selectedTask?.payment_id, selectedTask?.p_id,selectedTask?.name)} className='text-xs cursor-pointer bg-green-200 text-green-700 border border-green-600 px-3 py-1 rounded-xl w-fit'><FontAwesomeIcon icon={faCheckSquare} /> Mark As Completed</p>

                <p onClick={()=>updateTaskStatus(selectedTask?.user_id,"failed",selectedTask?.subs_id, selectedTask?.payment_id, selectedTask?.p_id,selectedTask?.name)} className='text-xs cursor-pointer bg-red-200  text-red-700 border border-red-600 px-3 py-1 rounded-xl w-fit'><FontAwesomeIcon icon={faCancel} /> Mark as Failed</p>

                <p onClick={()=>updateTaskStatus(selectedTask?.user_id,"refunded",selectedTask?.subs_id, selectedTask?.payment_id, selectedTask?.p_id,selectedTask?.name)} className='text-xs cursor-pointer bg-blue-200  text-blue-700 border border-blue-600 px-3 py-1 rounded-xl w-fit'><FontAwesomeIcon icon={faRetweet} /> Mark as Refunded</p>


              </div>
              <p className='text-xs'>Note: </p>
              <p className='text-xs text-green-500'>If you mark as completed the subscription.</p>
              <p className='text-xs text-red-500'>If you mark as rejected, payment not verified</p>
              <p className='text-xs text-blue-500'>If you mark as refunded </p>
            </div>
          </div>


        </div>
      </div>}
      {/* Modal */}


      <table className='w-full overflow-x-auto'>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Contact</th>
            <th>Subscriptions</th>
            <th>Payments</th>
            
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {subscriptions.map((subscription, index) => (
            <tr className='border-b' key={index}>
              <td>{index+1}</td>
              <td>
                {subscription.full_name}
                <p className='text-xs text-green-600'>start: {subscription?.subscription_start}</p>
                <p className='text-xs text-red-700'>End: {subscription?.subscription_end}</p>
              </td>
              <td className='text-xs'>
                <p className='text-blue-500'>Email: {subscription.email}</p>
                <p className='text-red-500'>Phone: {subscription.phone}</p>
                <p className='text-green-500'>DOB: {subscription.dob}</p>
              </td>
              <td className='text-xs'>
                <p>Prev: {subscription?.subscription}</p>
                <p className='text-xs cursor-pointer bg-blue-200  text-blue-700 border border-blue-600 px-3 py-[2px] rounded-xl w-fit'>{subscription?.status}</p>
                <p className='text-gray-500'>New : {subscription?.name}</p>
              </td>
              <td>
                <div className='text-xs'>
                <p>Method: {subscription?.payment_method} | id: {subscription?.payment_id}</p>
                <p>Trx id: <b>{subscription.transaction_id}</b></p>
                </div>
              </td>
              <td className='flex space-x-2'>
                <p onClick={()=>onOffModal(subscription)} className='text-xs cursor-pointer bg-green-200 text-green-700 border border-green-600 px-3 py-1 rounded-xl w-fit'><FontAwesomeIcon icon={faEye} /> Preview</p>

                <p onClick={()=>DeletePackage(subscription?.p_id)} className='text-xs cursor-pointer bg-red-200  text-red-700 border border-red-600 px-3 py-1 rounded-xl w-fit'><FontAwesomeIcon icon={faTrash} /> Delete</p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SubmittedPaymentTable;
