import { faBell, faBuilding, faQuestionCircle, faUser } from '@fortawesome/free-regular-svg-icons';
import { faCaretRight, faChevronRight, faLock, faMapMarker, faSubscript, faTasks, faUsers, faVideo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../headers/header';
import { useSelector } from 'react-redux';

const Sidebar = () => {
    const [showSidebar, setshowSidebar] = useState(false);
    
    const handlBar = ()=>{
        setshowSidebar(!showSidebar)
    }
    return (
        <>
            <Header handlBar={handlBar}/> 
            <div className={`w-full ${!showSidebar&&"hidden"} lg:block lg:w-1/4 fixed z-40 lg:static bg-[rgba(255,255,255,0.4)]`}>
                <div className='w-full relative bg-white p-10 h-screen text-left'>
                    <div className='pt-20'>
                        <h1 className='text-gray-600 text-md mb-4'>ADMIN ACCOUNT</h1>
                        <Link to={'/'} className='flex justify-between items-center p-2'>
                            <p>
                                <FontAwesomeIcon icon={faUser}/>
                                <span> Subscriptions</span>
                            </p>
                            <FontAwesomeIcon size='sm' icon={faChevronRight}/>
                        </Link>
                        <Link to={'/tasks'} className='flex justify-between items-center p-2'>
                            <p>
                                <FontAwesomeIcon icon={faLock}/>
                                <span> Tasks</span>
                            </p>
                            <FontAwesomeIcon size='sm' icon={faChevronRight}/>
                        </Link>
                        <Link to={'/courses'} className='flex justify-between items-center p-2'>
                            <p>
                                <FontAwesomeIcon icon={faBell}/>
                                <span> Courses</span>
                            </p>
                            <FontAwesomeIcon size='sm' icon={faChevronRight}/>
                        </Link>
                        <Link to={'/users'} className='flex justify-between items-center p-2'>
                            <p>
                                <FontAwesomeIcon icon={faUsers}/>
                                <span> Users</span>
                            </p>
                            <FontAwesomeIcon size='sm' icon={faChevronRight}/>
                        </Link>
                        <Link to={'/submitted_task'} className='flex justify-between items-center p-2'>
                            <p>
                                <FontAwesomeIcon icon={faTasks}/>
                                <span> Submitted Tasks</span>
                            </p>
                            <FontAwesomeIcon size='sm' icon={faChevronRight}/>
                        </Link>
                        <Link to={'/category'} className='flex justify-between items-center p-2'>
                            <p>
                                <FontAwesomeIcon icon={faCaretRight}/>
                                <span> Category</span>
                            </p>
                            <FontAwesomeIcon size='sm' icon={faChevronRight}/>
                        </Link>
                        <Link to={'/videos'} className='flex justify-between items-center p-2'>
                            <p>
                                <FontAwesomeIcon icon={faVideo}/>
                                <span> Videos</span>
                            </p>
                            <FontAwesomeIcon size='sm' icon={faChevronRight}/>
                        </Link>
                        <Link to={'/submitted_payment'} className='flex justify-between items-center p-2'>
                            <p>
                                <FontAwesomeIcon icon={faSubscript}/>
                                <span> Subscription Req.</span>
                            </p>
                            <FontAwesomeIcon size='sm' icon={faChevronRight}/>
                        </Link>
                        <Link to={'/quizs'} className='flex justify-between items-center p-2'>
                            <p>
                                <FontAwesomeIcon icon={faQuestionCircle}/>
                                <span> Quizs</span>
                            </p>
                            <FontAwesomeIcon size='sm' icon={faChevronRight}/>
                        </Link>
                    </div><br></br>

                    
                </div>
            </div>
        </>
    );
}

export default Sidebar;
