import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Login from './componants/login/login';
import Forgotpassword from './componants/login/foregotpassword';
import Getstarted from './componants/signup/getstarted';
import Signup from './componants/signup/signup';
import Header from './componants/headers/header';


import Profile from './componants/profile/subscription';
import Tasks from './componants/profile/tasks';
import Notification from './componants/profile/notification';
import ComapnyDetails from './componants/profile/company-details';
import Address from './componants/profile/address';
import TeamAccess from './componants/profile/team-access';
import Subscription from './componants/profile/subscription';
import { useDispatch, useSelector } from 'react-redux';
import { login, loginAsync } from './features/login/loginSlice';
import { isjwtExpire } from './app/jwtcheck';
import Message from './componants/headers/Message';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import Courses from './componants/profile/courses';
import Users from './componants/profile/users';
import Category from './componants/profile/category';
import Submitted_task from './componants/profile/submitted_task';
import Videos from './componants/profile/videos';
import Quizs from './componants/profile/quizs';
import Submitted_payment from './componants/profile/submitted_payment';


function App() {
  const [userData, setuserData] = useState(null);
  const dispatch = useDispatch()
  const {user} = useSelector(state=>state.login)
  useEffect(() => {
    // sessionStorage.removeItem("login")
    let data = sessionStorage.getItem("login")
    data = JSON.parse(data)
    // console.log(data?.result[0]);
    setuserData(data)
   


    // const {email,password} = data?.result[0] || {};
    const isExpired = isjwtExpire(data?.token)
    // if(email && password){
      if(false){
        const {email,password} = data?.result[0] || {};
        dispatch(loginAsync({email,password})).unwrap().then((result)=>{
            console.log(result);
            dispatch(login(result))
            sessionStorage.setItem("login",JSON.stringify(result))
        })
      }else{
        dispatch(login(data))
      }
      
    // }

    
  }, []);
  return (
    <div className="App">
      
       <BrowserRouter>
       
       {
        isjwtExpire(user?.token)&&!user?.token&&userData&&<Message message={'Session Expired'} />
       }
        {isjwtExpire(user?.token)&&<Routes>
            <Route index path='/' element={<Login />} />
            <Route path='login' element={<Login />} />
            <Route path="getstarted" element={<Getstarted />} />
            <Route path="signup" element={<Signup/>} />
            <Route path="forgot-password" element={<Forgotpassword />} />
        </Routes>}
        {!isjwtExpire(user?.token)&&user?.token&&<Routes>
            <Route index path='/' element={<Subscription />} />
            <Route path="tasks" element={<Tasks />} />
            <Route path="courses" element={<Courses />} />
            <Route path="category" element={<Category/>} />
            <Route path="users" element={<Users />} />
            <Route path="submitted_payment" element={<Submitted_payment />} />
            <Route path="submitted_task" element={<Submitted_task />} />
            <Route path="videos" element={<Videos />} />
            <Route path="quizs" element={<Quizs />} />
            <Route path="team-access" element={<TeamAccess />} />
        </Routes>}
      </BrowserRouter>
          <ToastContainer/>
    </div>
  );
}

export default App;
