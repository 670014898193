import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';


const UserTable = ({ EditPackage, DeletePackage, subscriptions }) => {
  return (
    <div>
      <table className='w-full'>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Contact</th>
            <th>Points</th>
            <th>Subscription</th>
            
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {subscriptions.map((subscription) => (
            <tr className='border-b' key={subscription.id}>
              <td>{subscription.id}</td>
              <td>{subscription.full_name}</td>
              <td className='text-xs'>
                <p className='text-blue-500'>Email: {subscription.email}</p>
                <p className='text-red-500'>Phone: {subscription.phone}</p>
                <p className='text-green-500'>DOB: {subscription.dob}</p>
              </td>
              <td>
                <p>Points: {subscription.points}</p>
                <p className='text-gray-500'>Join in: {moment(subscription.created_at).format("MMM Do YY") }</p>
              </td>
              <td>
                <div className='flex flex-wrap'>
                <p>{subscription.subscription}</p>
                </div>
              </td>
              <td className='flex space-x-2'>
                {/* <p onClick={()=>EditPackage(subscription)} className='text-xs cursor-pointer bg-green-200 text-green-700 border border-green-600 px-3 py-1 rounded-xl w-fit'><FontAwesomeIcon icon={faEdit} /> Edit</p>
                <p onClick={()=>DeletePackage(subscription.id)} className='text-xs cursor-pointer bg-red-200  text-red-700 border border-red-600 px-3 py-1 rounded-xl w-fit'><FontAwesomeIcon icon={faTrash} /> Delete</p> */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserTable;
