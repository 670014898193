import React, { useCallback, useEffect, useState } from 'react';
import Sidebar from './sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-regular-svg-icons';
import { faArrowRight, faUser, faUsers } from '@fortawesome/free-solid-svg-icons';
import SimpleMDE, { SimpleMdeReact } from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import MarkdownIt from 'markdown-it';
import { apiURL } from '../../app/lib';
import { useSelector } from 'react-redux';

import { ToastContainer, toast } from 'react-toastify';
import Message from '../headers/Message';

import htmlToMarkdown from '@wcj/html-to-markdown';

import UserTable from './users/usersTable';
import Table from './submitted_task/table';
import SubmittedPaymentTable from './submitted_payment/table';

const Submitted_payment = () => {
    const [value, setValue] = useState("");
    const [packages, setpackages] = useState([]);
    const [isLoadingSubmit, setisLoadingSubmit] = useState(false);
    const [isContentLoading, setisContentLoading] = useState(false);
    const [subscription_group, setsubscription_group] = useState('');
    const [isEditModon, setisEditModon] = useState(false);
    const {user} = useSelector(state=>state.login)
    
    
   
    const fetchAllPackage = ()=>{
        setisContentLoading(true)
        fetch(apiURL+"/common",{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
                "Authorization":user?.token
            },
            body:JSON.stringify({
                data:"",
                sql: `SELECT *, payments.id AS p_id, subscriptions.id AS subs_id,payments.created_at AS pay_at FROM payments INNER JOIN subscriptions ON payments.payment_id=subscriptions.payment_id RIGHT JOIN users ON payments.user_id=users.id RIGHT JOIN packages ON packages.id=subscriptions.package_id`,
            })
        })
        .then((res)=>res.json())
        .then((result)=>{
            if(result.status===200){
                console.log(result.result);
                setpackages(result?.result)
            }else{
                toast(result?.message)
            }
            setisContentLoading(false)
            
        })
        .catch(()=>{
            toast("Package Not Fetch!")
            setisContentLoading(false)
        })
    }


    const DeletePackage = (id)=>{
        const confirmDelete = window.confirm("Are you sure you want to delete?");
        if(!confirmDelete){
            return 0;
        }
        fetch(apiURL+"/common",{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
                "Authorization":user?.token
            },
            body:JSON.stringify({
                data:'',
                sql:`DELETE FROM payments WHERE id=${id}`
            })
        })
        .then((res)=>res.json())
        .then((result)=>{
           
            if(result.status===200){
                toast(result?.message)
                fetchAllPackage()
            }else{
                toast(result?.message)
            }
           
            
        })
        .catch(()=>{
            toast("Task Not Deleted!")
            
        })
    }
//selectedTask?.user_id,"completed",selectedTask?.subs_id, selectedTask?.payment_id,, selectedTask?.p_id,selectedTask?.name
    const UpdateTaskSubmit = (user_id, status,sub_id, pay_id,p_id,packageName)=>{
        const confirmDelete = window.confirm("Are you sure?");
        if(!confirmDelete){
            return 0;
        }
        fetch(apiURL+"/update_payment",{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
                "Authorization":user?.token
            },
            body:JSON.stringify({
                user_id:user_id,
                payment_id:pay_id,
                subscription_id:sub_id,
                pid:p_id,
                status:status,
                packageName:packageName
            })
        })
        .then((res)=>res.json())
        .then((result)=>{
           
            if(result.status===200){
                toast(result?.message)
                fetchAllPackage()
            }else{
                toast(result?.message)
            }
           
            
        })
        .catch(()=>{
            toast("Task Not Deleted!")
            
        })
    }

    const HandleEdit = (id)=>{
        
        setisLoadingSubmit(true)
        fetch(apiURL+"/edit-task/"+id,{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
                "Authorization":user?.token
            },
            body:JSON.stringify()
        })
        .then((res)=>res.json())
        .then((result)=>{
            if(result.status===200){
                toast(result?.message)
                
                setValue('')
                setisEditModon(false)
                fetchAllPackage()
            }else{
                toast(result?.message)
            }
            setisLoadingSubmit(false)
            
        })
        .catch(()=>{
            toast("Package Not Saved!")
            setisLoadingSubmit(false)
        })
    }

    const EditPackage = (info)=>{
        htmlToMarkdown({ html: info.description }).then((r)=>{
            setisEditModon(true)
            setValue(r)
            setsubscription_group(info.subcription_group)
            
            // console.log(r);
        })
        
        

    }
    
 


      useEffect(() => {
        fetchAllPackage()
      }, []);
    return (
        <div className='flex bg-[#f7f9fc]'>
            
            <Sidebar/>
            
            <div className='w-full lg:w-4/5 p-5 mt-16 lg:p-10 text-left'>
                <div className='border-b pb-2 flex justify-between'>
                    <p className='text-xl font-bold'>Users </p>
                    <FontAwesomeIcon size='xl' icon={faUsers} />
                </div>
                <br></br>
                <br></br>
                
                <div className='relative'>
                    {
                        isContentLoading&&
                        <div className='absolute bg-[rgba(0,0,0,0.2)] w-full left-0 top-0 h-full z-50 p-10'><Message message={"Loading..."} /></div>
                    }
                    {
                        !isContentLoading&&
                        <SubmittedPaymentTable EditPackage={EditPackage} updateTaskStatus = {UpdateTaskSubmit} DeletePackage={DeletePackage} subscriptions={packages} />
                    }
                </div>

            </div>
            


        </div>
    );
}

export default Submitted_payment;
