import { faCheckSquare, faEdit, faEye } from '@fortawesome/free-regular-svg-icons';
import { faCancel, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useState } from 'react';


const Table = ({ EditPackage, DeletePackage, subscriptions,updateTaskStatus }) => {
  const [selectedTask, setselectedTask] = useState(null);
  const [showModal, setshowModal] = useState(false);
 

  const sanitizeHtml = (html) => {
    
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.innerHTML;
  };

  const onOffModal = (task=null)=>{
    setshowModal(!showModal)
    if(!task){
      setselectedTask(null)
    }else{
      setselectedTask(task)
      
    }
    
  }
  useEffect(() => {
    
  }, []);
  return (
    <div className='relative'>
      {showModal&&selectedTask?.id&&<div className='fixed top-0 left-0 w-full h-screen z-50 p-2 lg:p-5 bg-[rgba(0,0,0,0.5)] flex justify-center'>
        <div className='bg-white transition-all w-11/12 p-3 lg:p-5 rounded-md h-fit max-h-[90vh] overflow-y-auto'>
          <div className='flex justify-between items-center p-2 pb-3 border-b'>
            <div>
              <h1 className='text-xl'>{selectedTask?.task_title}</h1>
              <p className='text-gray-500 text-xs'>submitted by {selectedTask?.full_name} | {selectedTask?.updated_at}</p>
            </div>
            <p className='cursor-pointer text-xl' onClick={onOffModal}>&times;</p>
          </div>

          <div className='my-5'>
            <h1 className='text-xl text-red-700 mb-2'>Task Description</h1>
            <hr></hr>
            <br></br>
            <div className='text-gray-700' dangerouslySetInnerHTML={{ __html: sanitizeHtml(selectedTask?.description) }} />
          </div>

          <div className='my-5'>
            <h1 className='text-xl text-red-700 mb-2'>User Submission</h1>
            <hr></hr>
            <br></br>
            {selectedTask?.comments&&<div className='text-gray-700' dangerouslySetInnerHTML={{ __html: sanitizeHtml(selectedTask?.comments) }} />}
            
            <br></br>
            <h1 className='text-xl text-red-700 mb-2'>Atachments</h1>
            <hr></hr>
            <br></br>
            <div className='flex flex-wrap'>
              {
              selectedTask?.screenshots && Array.isArray(JSON.parse(selectedTask?.screenshots)) &&
              JSON.parse(selectedTask?.screenshots)?.map((image)=>{
                return <a target='_blank' href={"https://guider.exlbyte.com/images/task_screenshots/"+image?.photo} ><img className='h-32 w-32 border-2 m-1' src={"https://guider.exlbyte.com/images/task_screenshots/"+image?.photo} /></a>
              })
              
              }
            </div><br></br>
            <div>
              <div className='flex space-x-2 bg-white shadow-md m-2 p-5 border'>
                <p onClick={()=>updateTaskStatus(selectedTask?.task_complete_id,"completed",0, selectedTask?.task_id,selectedTask?.user_id)} className='text-xs cursor-pointer bg-green-200 text-green-700 border border-green-600 px-3 py-1 rounded-xl w-fit'><FontAwesomeIcon icon={faCheckSquare} /> 0</p>
                <p onClick={()=>updateTaskStatus(selectedTask?.task_complete_id,"completed",2, selectedTask?.task_id,selectedTask?.user_id)} className='text-xs cursor-pointer bg-green-300 text-green-700 border border-green-600 px-3 py-1 rounded-xl w-fit'><FontAwesomeIcon icon={faCheckSquare} /> 2</p>
                <p onClick={()=>updateTaskStatus(selectedTask?.task_complete_id,"completed",4, selectedTask?.task_id,selectedTask?.user_id)} className='text-xs cursor-pointer bg-green-400 text-green-700 border border-green-600 px-3 py-1 rounded-xl w-fit'><FontAwesomeIcon icon={faCheckSquare} /> 4</p>
                <p onClick={()=>updateTaskStatus(selectedTask?.task_complete_id,"completed",6, selectedTask?.task_id,selectedTask?.user_id)} className='text-xs cursor-pointer bg-green-500 text-green-700 border border-green-600 px-3 py-1 rounded-xl w-fit'><FontAwesomeIcon icon={faCheckSquare} /> 6</p>
                <p onClick={()=>updateTaskStatus(selectedTask?.task_complete_id,"completed",8, selectedTask?.task_id,selectedTask?.user_id)} className='text-xs cursor-pointer bg-green-600 text-green-200 border border-green-600 px-3 py-1 rounded-xl w-fit'><FontAwesomeIcon icon={faCheckSquare} /> 8</p>
                <p onClick={()=>updateTaskStatus(selectedTask?.task_complete_id,"completed",10, selectedTask?.task_id,selectedTask?.user_id)} className='text-xs cursor-pointer bg-green-700 text-green-200 border border-green-600 px-3 py-1 rounded-xl w-fit'><FontAwesomeIcon icon={faCheckSquare} /> 10</p>

                {/* <p onClick={()=>updateTaskStatus(selectedTask?.task_complete_id,"rejected",selectedTask?.task_points, selectedTask?.task_id,selectedTask?.user_id)} className='text-xs cursor-pointer bg-red-200  text-red-700 border border-red-600 px-3 py-1 rounded-xl w-fit'><FontAwesomeIcon icon={faCancel} /> Mark as Rejected</p> */}
              </div>
              {/* <p className='text-xs'>Note: </p>
              <p className='text-xs text-green-500'>If you mark as completed user will receive {selectedTask?.task_points} points</p>
              <p className='text-xs text-red-500'>If you mark as rejected user will not recieve any points</p> */}
            </div>
          </div>


        </div>
      </div>}
      <table className='w-full overflow-x-auto'>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Contact</th>
            <th>Points</th>
            <th>Task</th>
            
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {subscriptions.map((subscription, index) => (
            <tr className='border-b' key={index}>
              <td>{index+1}</td>
              <td>{subscription.full_name}</td>
              <td className='text-xs'>
                <p className='text-blue-500'>Email: {subscription.email}</p>
                <p className='text-red-500'>Phone: {subscription.phone}</p>
                <p className='text-green-500'>DOB: {subscription.dob}</p>
              </td>
              <td className='text-xs'>
                <p>Task Points: {subscription.obtained_mark}/{subscription.task_points}</p>
                <p className='text-xs cursor-pointer bg-blue-200  text-blue-700 border border-blue-600 px-3 py-[2px] rounded-xl w-fit'>{subscription?.status}</p>
                
              </td>
              <td>
                <div className='text-xs'>
                {/* <p>{subscription.subscription}</p> */}
                <p className='text-gray-500'>Submitted : {moment(subscription.updated_at).format("MMM Do YY") }</p>
                <p>Task Name: <b>{subscription.task_title}</b></p>
                </div>
              </td>
              <td className='flex space-x-2'>
                <p onClick={()=>onOffModal(subscription)} className='text-xs cursor-pointer bg-green-200 text-green-700 border border-green-600 px-3 py-1 rounded-xl w-fit'><FontAwesomeIcon icon={faEye} /> Preview</p>

                <p onClick={()=>DeletePackage(subscription.task_complete_id)} className='text-xs cursor-pointer bg-red-200  text-red-700 border border-red-600 px-3 py-1 rounded-xl w-fit'><FontAwesomeIcon icon={faTrash} /> Delete</p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
